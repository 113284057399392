import React from 'react';
import {
  AssetVersionCommentDto,
  AssetVersionCommentGroupMentionDto,
  AssetVersionCommentMentionDto
} from '@api/Api';
import { isJsonString } from '@helpers/isJsonString';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { openTaskModal } from '@redux/reducers/tasks';
import { Avatar, Tooltip, Comment } from 'antd';
import classNames from 'classnames';
import { ReactComponent as MagicianWhiteSvg } from '@assets/icons/magician-white.svg';
import { ReactComponent as DrawingToolSvg } from '@assets/icons/drawing-tool.svg';
import { ReactComponent as ReplySvg } from '@assets/icons/reply-comment.svg';
import { ReactComponent as BlankSvg } from '@assets/icons/blank.svg';
import { ReactComponent as HeartSvg } from '@assets/icons/heart.svg';
import UserAvatar from '@components/UserAvatar';
import CommentEditor from '@pages/MediaViewer/Comments/components/CommentEditor';
import type { SubmitEditorValues } from '@pages/MediaViewer/Comments/components/CommentEditor/CommentEditor';
import CommentItem from '@pages/MediaViewer/Comments/components/CommentItem';
import { useAssetMVPermissions, useTypedSelector } from '@hooks';
import { taskStateSelector } from '@redux/selectors/tasks';

type CommentType = AssetVersionCommentDto & {
  level: number;
  children: CommentType[];
};

interface CommentTreeProps {
  commentMentions: boolean;
  comment: CommentType;
  editCommentId: string | null;
  selectedCommentId: string | null;
  replyToCommentId: string | null;
  showReplyOfCommentId: string | null;
  isCheckboxChecked: boolean;
  checkboxValueType: 'timeCode' | 'pageNumber' | null;
  potentialMentions: AssetVersionCommentMentionDto[];
  potentialGroupMentions: AssetVersionCommentGroupMentionDto[];
  searchPotentialMentions: (search: string) => void;
  onReply: (comment: CommentType) => void;
  onLikeClick: (comment: CommentType) => void;
  onShowReplyClick: (comment: CommentType) => void;
  onAddComment: (values: SubmitEditorValues) => Promise<void>;
  setEditCommentId: (commentId: string | null) => void;
  setReplyToCommentId: (commentId: string | null) => void;
  setIsCheckboxChecked: (checked: boolean) => void;
  onEditComment: (values: SubmitEditorValues) => Promise<void>;
  onDeleteComment: (commentId: string) => void;
  onTurnCommentIntoTask: (comment: AssetVersionCommentDto) => void;
  canCreateTask: boolean;
}

export default function CommentTree(props: CommentTreeProps) {
  const {
    commentMentions,
    comment,
    editCommentId,
    selectedCommentId,
    showReplyOfCommentId,
    replyToCommentId,
    isCheckboxChecked,
    checkboxValueType,
    potentialMentions,
    potentialGroupMentions,
    searchPotentialMentions,
    onReply,
    onLikeClick,
    onShowReplyClick,
    onAddComment,
    setEditCommentId,
    setReplyToCommentId,
    setIsCheckboxChecked,
    onEditComment,
    onDeleteComment,
    onTurnCommentIntoTask,
    canCreateTask
  } = props;
  const canLeaveComment = useAssetMVPermissions('comment');
  const taskId = useTypedSelector((state) => {
    if (!comment.task?.id) return null;
    const taskState = taskStateSelector(state, { taskId: comment.task.id });
    if (!taskState.entity) return comment.task.id;
    return taskState.entity.assetVersionComment?.id === comment.id
      ? comment.task.id
      : null;
  });

  const actions = [];
  const dispatch = useTypedDispatch();
  const hasAnnotations = comment.annotations?.length;
  const hasDocumentHighlight = comment.documentHighlight?.length;
  if (
    comment.annotations &&
    isJsonString(comment.annotations) &&
    JSON.parse(comment.annotations).length > 0 &&
    comment.annotations.indexOf('type') >= 0 &&
    hasAnnotations &&
    !hasDocumentHighlight
  ) {
    actions.push(
      <div key="has-annotations">
        <span className="hasAnnotations">
          <MagicianWhiteSvg />
        </span>
      </div>
    );
  }
  if (hasDocumentHighlight) {
    actions.push(
      <div key="has-annotations">
        <span className="hasAnnotations">
          <DrawingToolSvg />
        </span>
      </div>
    );
  }
  if (taskId) {
    actions.push(
      <div key="has-task">
        <span key="has-task" className="hasTask">
          <span
            className="task-tag"
            onClick={() => {
              dispatch(
                openTaskModal({
                  taskId,
                  descriptionPlaceholder:
                    'Describe your task so your teammates know what to do.'
                })
              );
            }}
          >
            <BlankSvg />
            Task
          </span>
        </span>
      </div>
    );
  }
  if (canLeaveComment) {
    actions.push(
      <div key="comment-reply">
        <span onClick={() => onReply(comment)} className="comment-reply">
          <span>Reply</span>
          <ReplySvg />
        </span>
      </div>
    );
  }

  const hasLike = comment.likes.find((el: any) => el.me);
  const countLikes = comment.likes?.length || 0;
  const showLikeBtn = canLeaveComment || (!canLeaveComment && countLikes > 0);
  actions.push(
    <div
      key="comment-like"
      className={classNames(hasLike && 'liked', 'comment-like')}
    >
      {showLikeBtn && (
        <span
          onClick={() => onLikeClick(comment)}
          className="comment-like-icon"
          style={!canLeaveComment ? { pointerEvents: 'none' } : undefined}
        >
          <HeartSvg />
        </span>
      )}

      {countLikes !== 0 && (
        <Tooltip
          overlayClassName="media_viewer_tooltip"
          className="comment-like-number"
          title={comment.likes.map((like: any) => (
            <Avatar.Group
              key={like.id}
              maxCount={9}
              size="small"
              className="comment-like_avatar-group"
              prefixCls="ant-avatar-group"
              maxPopoverTrigger="click"
            >
              <UserAvatar
                isActive={true}
                size={22}
                src={like.picture || ''}
                userEmail={like.email || ''}
                userName={`${like.firstName} ${like.lastName}`}
              />
            </Avatar.Group>
          ))}
          placement="bottom"
        >
          {countLikes}
        </Tooltip>
      )}
    </div>
  );
  // }

  if (comment.children.length > 0) {
    actions.push(
      <div key="comment-replies" className="last">
        <span
          onClick={() => onShowReplyClick(comment)}
          className="comment-replies"
        >
          <Avatar.Group
            maxCount={2}
            size="small"
            prefixCls="ant-avatar-group media_viewer_comment_item-reply"
          >
            {comment.children
              .filter(
                (el: any, idx: any) =>
                  idx ===
                  comment.children.findIndex(
                    (object: any) => object.user?.id === el.user?.id
                  )
              )
              .map((replyComment: any) => {
                return (
                  <UserAvatar
                    key={replyComment.id}
                    isActive={true}
                    size={22}
                    src={replyComment.user?.picture.url}
                    userEmail={replyComment.user?.email}
                    userName={`${replyComment.user?.firstName} ${replyComment.user?.lastName}`}
                  />
                );
              })}
          </Avatar.Group>
          <span>
            {comment.children.length}{' '}
            {comment.children.length > 1 ? 'Replies' : 'Reply'}
          </span>
        </span>
      </div>
    );
  }

  return (
    <Comment
      key={comment.id}
      actions={actions}
      className={classNames(
        (comment.id === selectedCommentId || comment.id === replyToCommentId) &&
          'selected_comment',
        comment.id === replyToCommentId && 'is_show_reply_form'
      )}
      content={
        <div>
          {comment.id === editCommentId ? (
            <CommentEditor
              commentMentions={commentMentions}
              comment={comment}
              editorFor="edit"
              editCommentId={comment.id}
              setEditCommentId={setEditCommentId}
              replyToCommentId={replyToCommentId}
              setReplyToCommentId={setReplyToCommentId}
              isFocused={true}
              onSubmitEditor={onEditComment}
              isCheckboxChecked={isCheckboxChecked}
              setIsCheckboxChecked={setIsCheckboxChecked}
              checkboxValueType={checkboxValueType}
              potentialMentions={potentialMentions}
              potentialGroupMentions={potentialGroupMentions}
              searchPotentialMentions={searchPotentialMentions}
            />
          ) : (
            <div
              data-comment-id={comment.id}
              style={{ scrollMarginTop: '90px' }}
            >
              <CommentItem
                setEditCommentId={setEditCommentId}
                setReplyToCommentId={setReplyToCommentId}
                comment={comment}
                onDeleteComment={onDeleteComment}
                canCreateTask={canCreateTask}
                onTurnCommentIntoTask={onTurnCommentIntoTask}
              />
            </div>
          )}
        </div>
      }
    >
      <>
        {comment.id === replyToCommentId && (
          <CommentEditor
            commentMentions={commentMentions}
            comment={comment}
            editorFor="reply"
            editCommentId={null}
            setEditCommentId={setEditCommentId}
            replyToCommentId={replyToCommentId}
            setReplyToCommentId={setReplyToCommentId}
            isFocused={true}
            onSubmitEditor={onAddComment}
            isCheckboxChecked={isCheckboxChecked}
            setIsCheckboxChecked={setIsCheckboxChecked}
            checkboxValueType={checkboxValueType}
            potentialMentions={potentialMentions}
            potentialGroupMentions={potentialGroupMentions}
            searchPotentialMentions={searchPotentialMentions}
          />
        )}
        {comment.id === showReplyOfCommentId &&
          comment.children.map((comment) => (
            <CommentTree
              commentMentions={commentMentions}
              key={comment.id}
              comment={comment}
              selectedCommentId={selectedCommentId}
              editCommentId={editCommentId}
              replyToCommentId={replyToCommentId}
              showReplyOfCommentId={showReplyOfCommentId}
              isCheckboxChecked={isCheckboxChecked}
              checkboxValueType={checkboxValueType}
              onLikeClick={onLikeClick}
              onReply={onReply}
              onShowReplyClick={onShowReplyClick}
              onAddComment={onAddComment}
              setEditCommentId={setEditCommentId}
              setReplyToCommentId={setReplyToCommentId}
              setIsCheckboxChecked={setIsCheckboxChecked}
              onEditComment={onEditComment}
              onDeleteComment={onDeleteComment}
              onTurnCommentIntoTask={onTurnCommentIntoTask}
              canCreateTask={canCreateTask}
              potentialMentions={potentialMentions}
              potentialGroupMentions={potentialGroupMentions}
              searchPotentialMentions={searchPotentialMentions}
            />
          ))}
      </>
    </Comment>
  );
}
