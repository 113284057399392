import React, {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
  useLayoutEffect
} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { formatDuration } from '@helpers/formatDuration';
import { useSelectedMVComment, useTypedSelector } from '@hooks';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Tooltip,
  Input
} from 'antd';
import MagicianWhiteSvg from '@assets/icons/magician-white.svg';
import DrawingToolSvg from '@assets/icons/drawing-tool.svg';
import { ReactComponent as DoggySvg } from '@assets/icons/doggy.svg';
import { ReactComponent as SendSvg } from '@assets/icons/send.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import classNames from 'classnames';
import {
  AssetVersionCommentGroupMentionDto,
  AssetVersionCommentMentionDto,
  AudioVideoMetadataDto
} from '@api/Api';
import {
  selectComment,
  setCanvasMode,
  setCommentTextAction,
  setDocumentHighlight,
  setIsCommentFieldFocused,
  setIsTimecodeChecked
} from '@redux/actions/mediaViewerAction';
import { toggleSingInExternalReviewerModal } from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import UserAvatar from '@components/UserAvatar';
import { useAuth } from '@hooks/useAuth';
import { usePreviousInputValue } from '@hooks/usePreviousInputValue';
import useHasAnnotations from '@pages/MediaViewer/AssetViewer/Canvas/hooks/useHasAnnotations';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { hashedColor } from '@helpers/hashedColor';

const { TextArea } = Input;

export type SubmitEditorValues = {
  text: string;
  rawText: string;
  mentions: string[];
  groupMentions: string[];
  idOrParentId: string;
  documentHighlight?: string | null;
  documentHighlightRects?:
    | { top: number; left: number; width: number; height: number }[]
    | null;
  documentHighlightReplacement?: string | null;
};

function SendCommentBtn({
  isDisabled,
  editCommentId,
  setEditCommentId,
  editorFor,
  replyToCommentId,
  setReplyToCommentId,
  onSubmit
}: {
  isDisabled: boolean;
  editCommentId: string | null;
  setEditCommentId: (a: string | null) => void;
  editorFor: 'new' | 'reply' | 'edit';
  replyToCommentId: string | null;
  setReplyToCommentId: (a: string | null) => void;
  onSubmit: any;
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateComment = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      try {
        e.stopPropagation();
        if (loading) return;
        if ((!editCommentId && !replyToCommentId) || isDisabled) return;
        setLoading(true);
        await onSubmit();
      } catch (e) {
        console.warn(e, '-> onCreateComment');
        setLoading(false);
      }
    },
    [loading, editCommentId, replyToCommentId, isDisabled, setLoading, onSubmit]
  );

  const onEditComment = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        await onSubmit();
      } catch (e) {
        console.warn(e, '-> onEditComment');
        setLoading(false);
      }
    },
    [setLoading, onSubmit]
  );

  const onCancel = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setEditCommentId(null);
      setReplyToCommentId(null);
      dispatch(selectComment(null));
      dispatch(setDocumentHighlight(null));
      dispatch(setCanvasMode('view'));
    },
    [setEditCommentId, setReplyToCommentId, dispatch]
  );

  if (editorFor !== 'edit')
    return (
      <div
        className={classNames('send_comment', isDisabled && 'b-is-disabled')}
        onClick={onCreateComment}
      >
        <SendSvg />
      </div>
    );
  return (
    <div className="send_comment">
      <Button
        type="text"
        className="rich_text_form_btn"
        htmlType="button"
        onClick={onCancel}
      >
        Cancel
      </Button>

      <Button
        type="primary"
        className="rich_text_form_btn"
        htmlType="submit"
        disabled={isDisabled}
        loading={loading}
        onClick={onEditComment}
      >
        Save
      </Button>
    </div>
  );
}

function CommentEditor({
  commentMentions,
  comment = null,
  setEditCommentId,
  autoFocus = true,
  editCommentId,
  editorFor,
  replyToCommentId,
  setReplyToCommentId,
  isFocused,
  onSubmitEditor,
  isCheckboxChecked,
  setIsCheckboxChecked,
  checkboxValueType = null,
  potentialMentions,
  potentialGroupMentions,
  searchPotentialMentions
}: {
  commentMentions: boolean;
  comment?: any;
  autoFocus?: boolean;
  setEditCommentId: (a: string | null) => void;
  editCommentId: any;
  editorFor: 'new' | 'reply' | 'edit';
  replyToCommentId: string | null;
  setReplyToCommentId: (a: string | null) => void;
  isFocused: boolean;
  onSubmitEditor: (value: SubmitEditorValues) => Promise<void>;
  isCheckboxChecked: boolean;
  setIsCheckboxChecked: (a: boolean) => void;
  checkboxValueType?: 'timeCode' | 'pageNumber' | null;
  potentialMentions: AssetVersionCommentMentionDto[];
  potentialGroupMentions: AssetVersionCommentGroupMentionDto[];
  searchPotentialMentions: (search: string) => void;
}) {
  const hasAnnotations = useHasAnnotations();
  const { onChangePrevValues, onResetPrevValues } = usePreviousInputValue();
  const [onAddNewMention, setOnAddNewMention] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<any>('');
  const [documentHighlightReplacement, setDocumentHighlightReplacement] =
    useState<string | null>(null);
  const [isCommentSending, setIsCommentSending] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const inputRef = useRef<any>();
  const playerTime = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.playerTime
  );
  const documentHighlight = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.documentHighlight
  );
  const hasDocumentHighlight = !!documentHighlight.text;

  const isCommentFieldFocused = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isCommentFieldFocused
  );
  const isCommentFieldFocusedRef = useRef(isCommentFieldFocused);
  isCommentFieldFocusedRef.current = isCommentFieldFocused;
  const activePageNumber = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.activePageNumber
  );
  const isTimecodeChecked = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isTimecodeChecked
  );
  const isSourceSelected = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isSourceSelected
  );
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const timeFormat = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.timeFormat
  );

  const commentDetails = useSelectedMVComment();

  const mentions = useMemo(
    () => [
      ...potentialGroupMentions.map((el) => ({
        type: 'group' as const,
        id: el.id,
        name: el.name,
        description: '',
        picture: (
          <Avatar
            size={40}
            shape="square"
            style={{ background: hashedColor(el.id, 'memberGroupAvatar') }}
          >
            {el.name[0].toUpperCase()}
          </Avatar>
        )
      })),
      ...potentialMentions.map((el) => ({
        type: 'user' as const,
        id: el.id,
        name: `${el.firstName} ${el.lastName ?? ''}`.trim(),
        description: el.email,
        picture: (
          <UserAvatar
            isActive={true}
            size="large"
            src={el.picture.url}
            userEmail={el.email}
            userName={`${el.firstName} ${el.lastName ?? ''}`.trim()}
          />
        )
      }))
    ],
    [potentialGroupMentions, potentialMentions]
  );

  const avatarIcon = useMemo(() => {
    if (!user) return '';
    if (isFocused && hasDocumentHighlight) return DrawingToolSvg as unknown;
    if (isFocused && hasAnnotations) return MagicianWhiteSvg as unknown;
    return user.pictureUrl || '';
  }, [user, isFocused, hasAnnotations, hasDocumentHighlight]);

  const [selectedMentions, setSelectedMentions] = useState<
    { id: string; name: string; type: 'user' | 'group' }[]
  >([]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!isCommentFieldFocused) return;

    inputRef.current.focus();
  }, [isCommentFieldFocused]);

  useEffect(() => {
    setIsCheckboxChecked(isTimecodeChecked || hasDocumentHighlight);
  }, [isTimecodeChecked, hasDocumentHighlight, setIsCheckboxChecked]);

  useEffect(() => {
    if (editorFor === 'edit') {
      const replacementText =
        commentDetails?.documentHighlight !== documentHighlight.text
          ? documentHighlight.text
          : commentDetails?.documentHighlightReplacement;
      setDocumentHighlightReplacement(replacementText || '');
      return;
    }
    setDocumentHighlightReplacement(documentHighlight.text);
  }, [
    documentHighlight.text,
    commentDetails,
    editorFor,
    setDocumentHighlightReplacement,
    dispatch
  ]);

  useEffect(() => {
    if (!hasAnnotations) return;

    dispatch(setIsTimecodeChecked(true));
    setIsCheckboxChecked(true);
  }, [hasAnnotations, dispatch, setIsCheckboxChecked]);

  useEffect(() => {
    if (!commentDetails?.id) return;
    if (editorFor !== 'edit') return;
    if (comment?.id !== commentDetails?.id) return;
    setSelectedMentions([
      ...commentDetails.mentions.map((el) => ({
        id: el.id,
        name: `${el.firstName} ${el.lastName ?? ''}`.trim(),
        type: 'user' as const
      })),
      ...commentDetails.groupMentions.map((el) => ({
        id: el.id,
        name: el.name,
        type: 'group' as const
      }))
    ]);
    const _commentText = commentDetails.text
      .replace(/<br[^>]*>/gi, '\n')
      .replace(/<[^>]*>?/gm, '');
    setCommentText(_commentText);
    dispatch(setCommentTextAction(_commentText));
  }, [commentDetails, editorFor, comment?.id, dispatch]);

  const onSubmit = async () => {
    const text = commentText.replace(/\n/g, '<br />');
    let rawText = commentText.replace(/\n/g, '<br />');

    const id = editCommentId || replyToCommentId;
    selectedMentions.forEach((el: any) => {
      rawText = rawText.replace(`{{${el.id}}}`, `@${el.name}`);
    });

    await onSubmitEditor({
      text,
      rawText,
      mentions: selectedMentions
        .filter((el) => el.type === 'user')
        .filter((el) => text.includes(el.id))
        .map((el) => el.id),
      groupMentions: selectedMentions
        .filter((el) => el.type === 'group')
        .filter((el) => text.includes(el.id))
        .map((el) => el.id),
      idOrParentId: id,
      documentHighlight: documentHighlight.text,
      documentHighlightRects: documentHighlight.rects,
      documentHighlightReplacement
    });
    setEditCommentId(null);
    setReplyToCommentId(null);
    setSelectedMentions([]);
    setCommentText('');
    setDocumentHighlightReplacement('');
    onResetPrevValues();
    dispatch(setCommentTextAction(null));
  };

  const mentionsCallbackRef = useRef<(mentions?: any) => void>();
  useLayoutEffect(() => {
    mentionsCallbackRef.current?.(mentions);
  }, [mentions]);

  const usersMenu = useMemo<MenuProps>(
    () => ({
      className: 'comment_mentions',
      selectable: false,
      items: mentions.map((item) => ({
        key: item.id,
        className: 'rich_text_form_invite-item',
        onClick: ({ key }) => {
          const selectedMentions = mentions.filter((el) => el.id === key);
          setSelectedMentions((prev) => [...prev, ...selectedMentions]);
          const text = `${commentText} {{${key}}}`;
          setCommentText(text);
          dispatch(setCommentTextAction(text));
        },
        label: (
          <Row wrap={false} gutter={16} align="middle">
            <Col>{item.picture}</Col>
            <Col flex="auto">
              {item && (
                <>
                  <div className="rich_text_form_invite-name">{item.name}</div>
                  {item.description && (
                    <div className="rich_text_form_invite-email">
                      {item.description}
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        )
      }))
    }),
    [mentions, commentText, dispatch]
  );

  const renderCommentTime = useCallback(
    (time: number) => {
      const metadata = selectedVersion?.metadata as AudioVideoMetadataDto;
      const sourceFrameRate = metadata.info.avInfo?.video?.frameRate ?? 30;
      const proxyFrameRate = Math.min(sourceFrameRate, 30);
      const frameRate = isSourceSelected ? sourceFrameRate : proxyFrameRate;

      if (timeFormat === 'frames') {
        return Math.floor(time * frameRate);
      }

      return formatDuration(time);
    },
    [isSourceSelected, selectedVersion?.metadata, timeFormat]
  );

  return (
    <div className="comment_editor">
      <div
        className={classNames(
          editCommentId && 'comment_editor_container-focused',
          'comment_editor_container'
        )}
        onClick={() => {
          if (!user) return;
          if (editorFor !== 'new') return;
          if (!isFocused && editorFor === 'new') {
            dispatch(setIsTimecodeChecked(true));
            dispatch(setDocumentHighlight(null));
          }

          setEditCommentId('new');
          dispatch(selectComment('new'));
          setReplyToCommentId(null);
        }}
      >
        {!user && (
          <div
            onClick={() => {
              dispatch(
                toggleSingInExternalReviewerModal({
                  visible: true
                })
              );
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1
            }}
          />
        )}
        {!!documentHighlight.text && isFocused && (
          <div className="comment_text-highlight">
            <TextArea
              prefixCls="comment_text-highlight-input"
              autoSize
              value={documentHighlightReplacement || ''}
              placeholder={
                documentHighlight.text ||
                commentDetails?.documentHighlight ||
                ''
              }
              onChange={(e) => setDocumentHighlightReplacement(e.target.value)}
            />
          </div>
        )}
        <div className="comment_editor_content">
          {user && (
            <div className="user_avatar">
              <UserAvatar
                isActive={true}
                size={24}
                src={avatarIcon as string}
                userEmail={user.email}
                userName={user.name}
              />
            </div>
          )}
          <MentionsInput
            inputRef={inputRef}
            autoFocus={autoFocus}
            allowSpaceInQuery
            disabled={!user}
            value={commentText}
            placeholder={
              (editorFor === 'new' && 'Write a comment..') ||
              (editorFor === 'reply' && 'Write a reply..') ||
              ''
            }
            onChange={(e) => {
              setCommentText(e.target.value);
              dispatch(setCommentTextAction(e.target.value));
              onChangePrevValues(e.target.value);
            }}
            onFocus={() => {
              dispatch(setIsCommentFieldFocused(true));
            }}
            onBlur={() => {
              searchPotentialMentions('');
              dispatch(setIsCommentFieldFocused(false));
            }}
            onKeyDown={async (event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                setOnAddNewMention(false);
                if (isCommentSending || onAddNewMention) return;
                if (
                  event.key === 'Enter' &&
                  !event.shiftKey &&
                  commentText.length
                ) {
                  event.preventDefault();
                  setIsCommentSending(true);
                  await onSubmit();
                  setIsCommentSending(false);
                }
              }
            }}
            className="comment_input"
            suggestionsPortalHost={
              document.querySelector('.comment_editor_mentions_list') ||
              undefined
            }
          >
            <Mention
              trigger={commentMentions ? '@' : /^\b$/}
              markup="{{__id__}}"
              data={(q, callback) => {
                callback(mentions);
                searchPotentialMentions(q);
                mentionsCallbackRef.current = callback;
              }}
              displayTransform={(id) => {
                const mention = selectedMentions.find((el) => el.id === id);
                return mention ? `@${mention.name}` : '';
              }}
              onAdd={(id) => {
                const selectedMentions = mentions.filter((el) => el.id === id);
                searchPotentialMentions('');
                setSelectedMentions((prev) => [...prev, ...selectedMentions]);
                setOnAddNewMention(true);
              }}
              appendSpaceOnAdd={true}
              renderSuggestion={(entry) => {
                const mention = mentions.find((el) => el.id === entry.id);
                if (!mention) return null;
                return (
                  <div style={{ zIndex: 9 }} className="container">
                    {mention.picture}
                    <div className="info">
                      <div className="name">{mention.name}</div>
                      <div className="email">{mention.description}</div>
                    </div>
                  </div>
                );
              }}
            />
          </MentionsInput>
          <div className="comment_editor_mentions_list" />
          {!isFocused && (
            <SendCommentBtn
              isDisabled={true}
              editCommentId={editCommentId}
              setEditCommentId={setEditCommentId}
              editorFor={editorFor}
              replyToCommentId={replyToCommentId}
              setReplyToCommentId={setReplyToCommentId}
              onSubmit={onSubmit}
            />
          )}
        </div>
        {isFocused && (
          <div className="comment_editor_tools">
            <div className="comment_editor_tools__left">
              <Tooltip
                placement="top"
                title="Mention someone"
                overlayClassName="rich_text_form_tooltip"
              >
                <Dropdown
                  menu={usersMenu}
                  placement="bottom"
                  trigger={['click']}
                  overlayClassName="editor_overlay"
                  disabled={!potentialMentions.length}
                >
                  <Button className="btn-mention">
                    <DoggySvg />
                  </Button>
                </Dropdown>
              </Tooltip>
              {!commentMentions && (
                <UpgradePlanTooltip
                  text=" to a Team plan to unlock mentions."
                  isCanVisible
                >
                  <Button className="btn-mention">
                    <UpgradePlanSvg />
                  </Button>
                </UpgradePlanTooltip>
              )}

              {checkboxValueType && (
                <>
                  {checkboxValueType === 'timeCode' && (
                    <Tooltip
                      placement="bottom"
                      title="The annotation must be attached to the timecode."
                      overlayClassName="rich_text_form_tooltip"
                    >
                      <Checkbox
                        onChange={(e) => {
                          setIsCheckboxChecked(e.target.checked);
                          dispatch(setIsTimecodeChecked(e.target.checked));
                        }}
                        checked={isCheckboxChecked}
                        className="rich_text_form_checkbox"
                        disabled={hasAnnotations}
                      >
                        {renderCommentTime(playerTime[0] || 0)}
                        {playerTime[1] && (
                          <> - {renderCommentTime(playerTime[1])}</>
                        )}
                      </Checkbox>
                    </Tooltip>
                  )}
                  {checkboxValueType === 'pageNumber' && (
                    <Tooltip
                      placement="bottomLeft"
                      title="The annotation must be attached to the page."
                      overlayClassName="rich_text_form_tooltip"
                    >
                      <Checkbox
                        onChange={(e) => {
                          setIsCheckboxChecked(e.target.checked);
                          dispatch(setIsTimecodeChecked(e.target.checked));
                        }}
                        checked={isCheckboxChecked}
                        className="rich_text_form_checkbox"
                        disabled={hasAnnotations || hasDocumentHighlight}
                      >
                        Page {activePageNumber}
                      </Checkbox>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div className="comment_editor_tools__right">
              <SendCommentBtn
                isDisabled={
                  !hasAnnotations && !commentText && !hasDocumentHighlight
                }
                editCommentId={editCommentId}
                setEditCommentId={setEditCommentId}
                editorFor={editorFor}
                replyToCommentId={replyToCommentId}
                setReplyToCommentId={setReplyToCommentId}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CommentEditor;
